
import { getBoard } from '@/api/board';
import { getPostList } from '@/api/post';
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import { IPost } from '@/types';
import Pagination from '@/components/board/pagination.vue';
import { UserModule } from '@/store/user';
import { addBookmark, deleteBookmark, getBookmarkListAll } from '@/api/userBookmark';

@Component({
  name: 'AppEventList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  mounted() {
    this.getBoard();
    this.getPostList();
    if (this.isLogined) this.getBookmarks();
  }

  get isLogined() {
    return UserModule.token;
  }

  get imagePath() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private board = null;

  private postList = [];

  private bookmarks: any = [];

  private listQuery = {
    page: 0,
    size: 10,
    searchType: 'all',
    searchValue: '',
    boardUid: '3e443ca9-92ff-4d50-877b-64f90c4d2c8a',
    categoryList: '',
    progress: 0,
    startDate: '',
    endDate: '',
  }

  private listTotal = 0;

  private totalPages = 0;

  private getBoard() {
    getBoard('3e443ca9-92ff-4d50-877b-64f90c4d2c8a').then((res) => {
      this.board = res.data;
    });
  }

  private getPostList() {
    getPostList(this.listQuery).then((res: any) => {
      this.listTotal = res.data.totalElements;
      this.postList = res.data.content;
      this.totalPages = res.data.totalPages;
    }).catch(() => {
      alert('게시글을 불러오는데 실패했습니다.');
    });
  }

  private getBookmarks() {
    getBookmarkListAll().then((res) => {
      this.bookmarks = res.data;
    });
  }

  private handleSearch() {
    this.listQuery.page = 0;
    this.getPostList();
  }

  private handleChangeProgress(progress: number) {
    this.listQuery.progress = progress;
    this.handleSearch();
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getPostList();
  }

  private isEnd(post: IPost) {
    if (!post.dataList[3].inputValue || !post.dataList[4].inputValue) return '진행중';
    const today = moment(moment().format('YYYY-MM-DD 00:00:00'));
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD');
    const remain = endDay.diff(today, 'days');
    if (remain >= 1) return `마감 ${remain} 일전`;
    if (remain >= 0) return '오늘 마감';
    return '마감';
  }

  private isEndClass(post: IPost) {
    if (!post.dataList[4].inputValue) return 'state01';
    const today = moment();
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD');
    const remain = endDay.diff(today, 'days');
    if (remain >= 30) return 'd-7';
    if (remain >= 7) return 'd-7';
    if (remain >= 0) return 'd-3';
    return 'd-day';
  }

  private getThumnail(inputValue: string) {
    if (inputValue.startsWith('http')) return inputValue;
    return `${this.imagePath}/${inputValue}`;
  }

  private isBookmark(postUid: string) {
    return this.bookmarks.indexOf(postUid) > -1;
  }

  private handleAddBookmark(postUid: string) {
    if (this.isLogined) {
      addBookmark(postUid).then(() => {
        this.bookmarks.push(postUid);
      });
    } else {
      alert('로그인이 필요한 서비스입니다.');
      this.$router.push({ name: 'AppLogin' });
    }
  }

  private handleDeleteBookmark(postUid: string) {
    if (this.isLogined) {
      deleteBookmark(postUid).then(() => {
        this.bookmarks.splice(this.bookmarks.indexOf(postUid), 1);
      });
    } else {
      alert('로그인이 필요한 서비스입니다.');
      this.$router.push({ name: 'AppLogin' });
    }
  }
}
